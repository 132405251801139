import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { H2 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import Button from "components/forms/button/button";
import RouterLink from "components/partials/router-link/router-link";
import CampaignEmailTable from "containers/admin/clients/campaign/components/campaign-email-table/campaign-email-table";
import CampaignLPTable from "containers/admin/clients/campaign/components/campaign-lp-table/campaign-lp-table";
import Popover from "components/partials/popover/popover";

import { useCurrentUser } from "state/ducks";

import { Route as AppRoute } from "utilities/app-routes";
import { hasPermission } from "utilities/user";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";
import { TouchpointTypeLabel } from "types/touchpoint";

const CampaignCreativePage = () => {
  const { EMAIL, EMAILS, LP_OTHER } = TouchpointTypeLabel;
  const currentUser = useCurrentUser();
  const [isNewCreativeDropdownOpen, setIsNewCreativeDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);

  const getCreativeTypeFromLocation = () => {
    if (location.pathname.includes(AppRoute.landingPages)) {
      return LP_OTHER;
    }
    return EMAILS;
  };

  const creativeType = getCreativeTypeFromLocation();

  const handleToggleChange = (option: TouchpointTypeLabel) => {
    navigate(option === EMAILS ? "../emails" : "../landing-pages");
  };

  const toggleNewCreativeDropdown = () => {
    setIsNewCreativeDropdownOpen((prev) => !prev);
  };

  return (
    <Box date-testid="client-campaign-creative-page">
      <PageContentHeader className="pt-5">
        <H2>Creative</H2>
        {hasPermission(currentUser, Permission.PERM_TOUCHPOINT_WRITE) && (
          <Popover
            matchWidth={true}
            width="inherit"
            isOpen={isNewCreativeDropdownOpen}
            onClose={() => setIsNewCreativeDropdownOpen(false)}
            triggerElement={
              <Button
                onClick={toggleNewCreativeDropdown}
                rightIcon={<ChevronDownIcon w={7} h={8} mr="-0.825rem" />}
                sx={{ py: "20px", px: "28px" }}>
                New creative
              </Button>
            }>
            <div className="flex flex-col gap-2">
              <RouterLink to="../emails/new">{EMAIL}</RouterLink>
              <RouterLink to="../landing-pages/new">{LP_OTHER}</RouterLink>
            </div>
          </Popover>
        )}
      </PageContentHeader>

      <ToggleButtonGroup
        groupName="campaign-creative-toggle"
        options={[EMAILS, LP_OTHER]}
        onChange={handleToggleChange}
        value={creativeType}
        mb={6}
      />

      {creativeType === EMAILS && <CampaignEmailTable />}
      {creativeType === LP_OTHER && <CampaignLPTable />}
    </Box>
  );
};

export default CampaignCreativePage;
