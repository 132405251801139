import { FlexProps } from "@chakra-ui/react";
import classNames from "classnames";

import Toggle from "components/partials/toggle/toggle";
import { default as BuilderViewToggle } from "components/partials/toggle-button-group/toggle-button-group";
import Button from "components/forms/button/button";

import { useCurrentUser } from "state/ducks";
import { TouchpointVersionAttributes } from "models/touchpoint-version";

import { hasPermission } from "utilities/user";
import { useBuilderAccess } from "containers/admin/clients/touchpoint/components/builder/use-builder-access";

import { Permission } from "types/auth";

export const builderPreviewMenuOptions = {
  mobileView: "Mobile",
  desktopView: "Desktop",
  htmlView: "HTML",
} as const;

export type BuilderPreviewViews =
  (typeof builderPreviewMenuOptions)[keyof typeof builderPreviewMenuOptions];

interface BuilderPreviewHeaderProps extends FlexProps {
  currentView: BuilderPreviewViews;
  selectedVersion?: TouchpointVersionAttributes;
  setCurrentView: (string: BuilderPreviewViews) => void;
  isCommentMode: boolean;
  setIsCommentMode: (isCommentMode: boolean) => void;
  isTouchpointPreview: boolean;
}

export const BuilderPreviewPaneHeader = ({
  currentView,
  selectedVersion,
  setCurrentView,
  isCommentMode,
  setIsCommentMode,
  isTouchpointPreview = false,
}: BuilderPreviewHeaderProps) => {
  const { accessBuilder } = useBuilderAccess();

  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const baseStyles = classNames("flex items-center w-full justify-between ", {
    "mb-2": isTouchpointPreview,
    "justify-center mb-8": !isTouchpointPreview,
  });

  const { mobileView, desktopView, htmlView } = builderPreviewMenuOptions;

  const viewToggleOptions: BuilderPreviewViews[] = [mobileView, desktopView];

  if (!isClientUser) {
    // Clients don't see the HTML view, everyone else does
    viewToggleOptions.push(htmlView);
  }

  return (
    <div className={baseStyles}>
      <Button
        className={!isTouchpointPreview || isClientUser ? "invisible" : ""}
        size="sm"
        variant="secondary"
        onClick={() => accessBuilder(selectedVersion)}>
        Open in editor
      </Button>
      <BuilderViewToggle
        groupName="preview-toggle"
        options={viewToggleOptions}
        defaultValue={currentView}
        onChange={(option) => setCurrentView(option)}
      />

      <Toggle
        customClass={currentView === htmlView ? "invisible" : ""}
        isChecked={isCommentMode}
        setIsChecked={setIsCommentMode}
      />
    </div>
  );
};
