import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { Stack } from "@chakra-ui/react";

import EmailDesignPreview from "containers/admin/clients/touchpoint/components/email-design-preview/email-design-preview";
import EmptyDesignPreview from "containers/admin/clients/touchpoint/components/empty-design-preview/empty-design-preview";
import {
  builderPreviewMenuOptions,
  BuilderPreviewPaneHeader,
  BuilderPreviewViews,
} from "containers/admin/clients/touchpoint/components/builder/builder-preview-pane-header/builder-preview-pane-header";
import { BuilderHtmlPreview } from "containers/admin/clients/touchpoint/components/builder/builder-html-preview/builder-html-preview";
import {
  EmailHeaders,
  initialEmailHeaders,
} from "containers/admin/clients/touchpoint/components/email-builder/email-header-preview/email-header-preview";

import { setCurrentBuilderContent, useTouchpointBuilderContent } from "state/ducks/builder-content";
import { useCurrentTouchpoint } from "state/ducks/touchpoint";
import Email from "models/email";
import TouchpointVersion from "models/touchpoint-version";

import { removeUserIdFromString } from "utilities/touchpoint";

const EmailBuilderPreviewTab = ({ previewMode: isPreviewMode }: { previewMode: boolean }) => {
  const currentTouchpoint = useCurrentTouchpoint();
  const currentVersion = useTouchpointBuilderContent() as Email;
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCommentMode, setIsCommentMode] = useState(false);

  const { desktopView, mobileView } = builderPreviewMenuOptions;
  const [currentView, setCurrentView] = useState<BuilderPreviewViews>(mobileView);
  const isDesktopPreview = currentView === desktopView;
  const isMobilePreview = currentView === mobileView;
  const isDesignPreview = isDesktopPreview || isMobilePreview;

  useEffect(() => {
    if (isPreviewMode) {
      TouchpointVersion.find({
        clientId: currentTouchpoint.clientId,
        campaignId: currentTouchpoint.campaignId,
        touchpointId: currentTouchpoint.id,
        id: currentVersion.id,
      }).then((res) => {
        dispatch(setCurrentBuilderContent(res.attributes));
      });
    }
    // eslint-disable-next-line
  }, []);

  const emailHeaders: EmailHeaders = {
    fromName: currentVersion.fromName || initialEmailHeaders.fromName,
    replyToEmail: currentVersion.replyToEmail || initialEmailHeaders.replyToEmail,
    subjectLine: currentVersion.subjectLine || initialEmailHeaders.subjectLine,
    preheader: currentVersion.preheader || initialEmailHeaders.preheader,
    senderEmail: currentVersion.senderEmail || initialEmailHeaders.senderEmail,
  };

  const builderTestId = isDesktopPreview
    ? "email-builder-desktop-previewType"
    : isMobilePreview
      ? "email-builder-mobile-previewType"
      : // isHtmlView
        "email-builder-html";

  const maxWidthClass = isMobilePreview ? "max-w-[25rem]" : `max-w-calc(100% - 0.5rem)`;

  const designTabRedirect = location.pathname.replace("preview", "design");

  return (
    <Stack
      data-testid={builderTestId}
      alignItems="center"
      flex="1"
      marginX="auto"
      maxWidth="container.lg"
      spacing="0"
      width="full">
      <BuilderPreviewPaneHeader
        currentView={currentView}
        setCurrentView={setCurrentView}
        isCommentMode={isCommentMode}
        setIsCommentMode={setIsCommentMode}
        isTouchpointPreview={false}
      />
      <div className={`w-full ${maxWidthClass}`}>
        {isDesignPreview ? (
          currentVersion.finalHtml && currentVersion.data?.jsonOutput ? (
            <EmailDesignPreview
              htmlContent={removeUserIdFromString(currentVersion.finalHtml)}
              htmlJSON={currentVersion.data?.jsonOutput}
              isCommentMode={isCommentMode}
              mobileView={isMobilePreview}
              emailHeaders={emailHeaders}
            />
          ) : (
            <EmptyDesignPreview redirectTo={designTabRedirect} />
          )
        ) : (
          <BuilderHtmlPreview
            data-testid="email-builder-html"
            textareaTestId="email-builder-advanced-textarea"
            value={currentVersion.finalHtml}
          />
        )}
      </div>
    </Stack>
  );
};

export default EmailBuilderPreviewTab;
