import { useNavigate } from "react-router-dom";
import { TouchpointVersionAttributes } from "models/touchpoint-version";
import { TouchpointType } from "types/touchpoint";
import { ContentStatus } from "types";
import { BuilderTabId } from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";

export const useBuilderAccess = () => {
  const navigate = useNavigate();
  const accessBuilder = (selectedVersion?: TouchpointVersionAttributes, tab?: BuilderTabId) => {
    if (!selectedVersion) return;
    const touchpointRouteType =
      selectedVersion.type === TouchpointType.EMAIL ? "email-builder" : "landing-page-builder";
    const endRoute =
      tab || (selectedVersion.status === ContentStatus.PUBLISHED ? "preview" : "design");
    const relativePath = `../versions/${selectedVersion.id}`;
    const touchpointFinalRoute = `${relativePath}/${touchpointRouteType}/${endRoute}`;
    navigate(touchpointFinalRoute);
  };
  const exitBuilder = () => {
    const entryPoint = sessionStorage.getItem("builderEntryPoint");
    if (entryPoint) {
      navigate(entryPoint);
      sessionStorage.removeItem("builderEntryPoint");
    } else {
      // Fallback to preview if we somehow lost the entry point
      navigate("../../../../preview");
    }
  };
  return { accessBuilder, exitBuilder };
};
