import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { TYPOGRAPHY_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/typography/typography.constants";

const Typography = () => {
  return (
    <div className="border-t border-zinc-200 flex flex-1">
      <PreviewSidebar sidebarItems={TYPOGRAPHY_TAB_SIDEBAR} />
      <PreviewArea />
    </div>
  );
};

export default Typography;
