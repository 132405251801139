import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import Color from "containers/admin/clients/client/theme-page/preview/sub-tabs/color/color";
import Fonts from "containers/admin/clients/client/theme-page/preview/sub-tabs/fonts/fonts";
import Typography from "containers/admin/clients/client/theme-page/preview/sub-tabs/typography/typography";
import Button from "containers/admin/clients/client/theme-page/preview/sub-tabs/button/button";

import { useState } from "react";

const THEME_PREVIEW_SUBTABS = ["Color", "Fonts", "Typography", "Button"] as const;
type TabType = (typeof THEME_PREVIEW_SUBTABS)[number];

const ThemePreview = () => {
  const [currentTab, setCurrentTab] = useState<TabType>("Color");

  return (
    <>
      <div className="flex px-8 py-3 justify-between items-center">
        <ToggleButtonGroup
          groupName="theme-preview-tabs-toggle"
          options={[...THEME_PREVIEW_SUBTABS]}
          onChange={(option) => setCurrentTab(option as TabType)}
          value={currentTab}
        />
      </div>
      <div className="content">
        {currentTab === "Color" && <Color />}
        {currentTab === "Fonts" && <Fonts />}
        {currentTab === "Typography" && <Typography />}
        {currentTab === "Button" && <Button />}
      </div>
    </>
  );
};

export default ThemePreview;
