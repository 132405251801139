// containers/app-routes-wrapper.tsx
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { currentUserSelector, setCurrentUser } from "state/ducks/users";
import AuthenticatedApp from "containers/authenticated-app";
import LoginPage from "containers/login-page/login-page";
import User from "models/user";
import Model from "models/model";
import axiosInstance from "config/axios-config";

const AppRoutesWrapper = () => {
  const { inProgress } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const [isApiConnected, setIsApiConnected] = useState(false);

  // Handle API connection
  useEffect(() => {
    const connectApi = async () => {
      if (
        isMsalAuthenticated &&
        !Model.isConnectionReady() &&
        inProgress === InteractionType.None
      ) {
        try {
          await Model.connect(axiosInstance);
          setIsApiConnected(true);
        } catch (error) {
          console.error("API connection failed:", error);
          setIsApiConnected(false);
        }
      }
    };

    connectApi();
  }, [isMsalAuthenticated, inProgress]);

  // Handle user data fetching
  useEffect(() => {
    const fetchUser = async () => {
      if (isApiConnected && Model.isConnectionReady()) {
        try {
          const user = await User.find();
          dispatch(setCurrentUser(user.attributes));
        } catch (error) {
          console.error("User fetch failed:", error);
          Model.disconnect();
          dispatch(setCurrentUser(null));
          setIsApiConnected(false);
        }
      }
    };

    fetchUser();
  }, [isApiConnected, dispatch]);

  const isAuthenticated = !!currentUser && isMsalAuthenticated && isApiConnected;

  // Only show login when we're certain authentication has failed
  if (inProgress !== InteractionType.None) {
    return null;
  }

  // Only show login when we're certain we're not authenticated
  if (!isMsalAuthenticated && inProgress === InteractionType.None) {
    return <LoginPage />;
  }

  // Show nothing while we're connecting to API or fetching user
  if (!isAuthenticated && (isMsalAuthenticated || isApiConnected)) {
    return null;
  }

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return <AuthenticatedApp />;
};

export default AppRoutesWrapper;
