import PreviewSidebar from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar";
import PreviewArea from "containers/admin/clients/client/theme-page/preview/preview-area/preview-area";

import { FONTS_TAB_SIDEBAR } from "containers/admin/clients/client/theme-page/preview/sub-tabs/fonts/fonts.constants";

const Fonts = () => {
  return (
    <div className="border-t border-zinc-200 flex flex-1">
      <PreviewSidebar sidebarItems={FONTS_TAB_SIDEBAR} />
      <PreviewArea />
    </div>
  );
};

export default Fonts;
