import { AddIcon } from "@chakra-ui/icons";

import Button, { ButtonProps } from "components/forms/button/button";
import { useCurrentUser } from "state/ducks";
import { Permission } from "types/auth";
import { hasPermission } from "utilities/user";

interface AddButtonProps extends ButtonProps {
  "aria-label"?: string;
}

export const AddButton = ({ "aria-label": ariaLabel, ...props }: AddButtonProps) => {
  const currentUser = useCurrentUser();

  if (!hasPermission(currentUser, Permission.PERM_CAMPAIGN_WRITE)) {
    return null;
  }
  return (
    <Button
      title={ariaLabel}
      aria-label={ariaLabel}
      leftIcon={<AddIcon />}
      px={7}
      py={5}
      {...props}>
      Add new
    </Button>
  );
};
