import { ApolloAccordion } from "@marketview/lunar-components";

import {
  PreviewSidebarItemsType,
  PreviewSidebarItemType,
} from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

const ThemePreviewSidebar = ({ sidebarItems }: PreviewSidebarItemsType) => {
  return (
    <div className="flex flex-col w-[320px] h-full border-r border-zinc-200 py-5 pr-6 pl-8 gap-5">
      {sidebarItems.map((el: PreviewSidebarItemType) => (
        <ApolloAccordion
          key={el.titleText}
          title={el.titleText}
          isOpenByDefault={el.isOpenByDefault}>
          {el.children}
        </ApolloAccordion>
      ))}
    </div>
  );
};

export default ThemePreviewSidebar;
