// email-builder.tsx
import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import EmailBuilderContentSettingsTab from "containers/admin/clients/touchpoint/components/email-builder/email-builder-content-settings-tab/email-builder-content-settings-tab";
import EmailBuilderDesignTab from "containers/admin/clients/touchpoint/components/email-builder/email-builder-design-tab/email-builder-design-tab";
import EmailBuilderPreviewTab from "containers/admin/clients/touchpoint/components/email-builder/email-builder-preview-tab/email-builder-preview-tab";
import Builder from "containers/admin/clients/touchpoint/components/builder/builder";
import {
  BuilderTabName,
  BuilderTabNav,
  BuilderTabId,
} from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";
import useEmailSettingsFields from "containers/admin/clients/touchpoint/components/email-builder/use-email-builder-form-fields";
import { useSaveTouchpoint } from "containers/admin/clients/touchpoint/components/builder/use-save-touchpoint";

import { Content, ContentWrapper } from "components/partials/layout/layout";
import Form, { FormProps } from "components/forms/form/form";
import { LoadingSpinner } from "components/partials/loading-screen/loading-screen";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";

import { useTouchpointBuilderContent } from "state/ducks/builder-content";
import { useCurrentTouchpoint } from "state/ducks/touchpoint";

import Email from "models/email";

import { usePagePermission } from "hooks/use-page-permission";

import { addAnnotationIdToElements } from "utilities";
import { BLANK_BEE_TEMPLATE } from "utilities/constants";

import { BeeContentDefaultProps } from "types/bee";
import { Permission } from "types/auth";

interface EmailBuilderProps {
  previewMode: boolean;
}

const EmailBuilder = ({ previewMode }: EmailBuilderProps) => {
  usePagePermission(Permission.PERM_TOUCHPOINT_READ);

  const touchpointContent = useTouchpointBuilderContent() as Email;
  const currentTouchpoint = useCurrentTouchpoint();

  const formFields = useEmailSettingsFields();
  const [invalidTabs, setInvalidTabs] = useState<BuilderTabName[]>([]);

  const [isNewTouchpoint, setIsNewTouchpoint] = useState(false);
  const saveTouchpoint = useSaveTouchpoint();
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [navigateTo, setNavigateTo] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(
    function detectNewTouchpoint() {
      if (!touchpointContent.data?.jsonOutput) {
        setIsNewTouchpoint(true);
      }
    },
    [touchpointContent],
  );

  useEffect(
    function triggerSetInitialValues() {
      if (isNewTouchpoint) {
        handleSetInitialValues();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNewTouchpoint],
  );

  const handleConfirm = () => {
    navigate(navigateTo);
    setShowModal(false);
    setInvalidTabs([]);
    setNavigateTo("");
  };

  async function handleSetInitialValues() {
    setIsSaving(true);

    try {
      const defaultFormValues = formFields.reduce(
        (acc, field) => {
          if (field.defaultValue === undefined || field.defaultValue === null) {
            acc[field.name] = "";
          } else {
            acc[field.name] = String(field.defaultValue);
          }
          return acc;
        },
        {} as Record<string, string>,
      );

      const newTouchpoint = {
        data: {
          htmlOutput: "<>",
          jsonOutput: addAnnotationIdToElements(BLANK_BEE_TEMPLATE),
        },
        sourceType: "BEE",
        ...defaultFormValues,
      };

      await saveTouchpoint(newTouchpoint);
      setIsSaved(true);
      setIsNewTouchpoint(false);
    } catch (error) {
      console.error("Error saving design", error); // Changed to console.error
      setIsSaved(false);
      // Add error handling to prevent infinite loading
      setIsNewTouchpoint(false); // Force exit from loading state on error
    }

    setIsSaving(false);
  }

  const lastPathSegment = location.pathname.split("/").pop();

  const currentTab = lastPathSegment as BuilderTabId;

  return (
    <Builder
      data-testid="email-builder"
      apiValue={touchpointContent}
      contentName={currentTouchpoint.name}
      previewMode={previewMode}
      permission={Permission.PERM_TOUCHPOINT_WRITE}
      isSaved={isSaved}
      setIsSaved={setIsSaved}
      isSaving={isSaving}>
      <BuilderTabNav
        data-testid="email-builder--tab-nav"
        isPreviewMode={previewMode}
        url={location.pathname}
        invalidTabs={invalidTabs}
        setShowConfirmation={() => setShowModal(true)}
        currentTab={currentTab}
        setNavigateTo={setNavigateTo}
      />

      <ContentWrapper>
        <Content backgroundColor="bg.content" pt={6}>
          {isNewTouchpoint ? (
            <div className="flex flex-1 justify-center items-center">
              <LoadingSpinner />
            </div>
          ) : (
            <Routes>
              <Route index element={<Navigate to={previewMode ? "preview" : "design"} replace />} />

              {!previewMode && (
                <Route
                  path="design"
                  element={
                    <EmailBuilderDesignTab
                      onSave={saveTouchpoint}
                      setIsSaved={setIsSaved}
                      setIsSaving={setIsSaving}
                    />
                  }
                />
              )}

              <Route
                path="content-settings"
                element={
                  <EmailBuilderContentSettingsTab
                    previewMode={previewMode}
                    formFields={formFields}
                    onSave={saveTouchpoint}
                    setIsSaved={setIsSaved}
                    setIsSaving={setIsSaving}
                    setInvalidTabs={setInvalidTabs}
                  />
                }
              />

              <Route
                path="preview"
                element={<EmailBuilderPreviewTab previewMode={previewMode} />}
              />
            </Routes>
          )}
          <ConfirmationModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            headline="Are you sure you want to leave the tab?"
            message="All invalid fields won't be saved. The form will be saved with the last valid values."
            cancelButtonText="Stay on the tab"
            confirmButtonText="Leave the tab"
            onConfirm={handleConfirm}
            modalType="warning"
          />
        </Content>
      </ContentWrapper>
    </Builder>
  );
};

export const TouchpointBuilderTabForm = ({ children, ...rest }: FormProps) => (
  <Form width="2xl" isCentered {...rest}>
    {children}
  </Form>
);

export interface BuilderTabProps {
  previewMode?: boolean;
  themeConfig?: BeeContentDefaultProps;
}

export default EmailBuilder;
