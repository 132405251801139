import { PreviewSidebarItemType } from "containers/admin/clients/client/theme-page/preview/preview-sidebar/preview-sidebar.types";

export const TYPOGRAPHY_TAB_SIDEBAR: PreviewSidebarItemType[] = [
  {
    titleText: "HEADING (H1)",
    children: <>Brand Hex Inputs come here...</>,
    isOpenByDefault: true,
  },
  {
    titleText: "SUBHEADING (H2)",
    children: <>Typography Inputs come here...</>,
    isOpenByDefault: true,
  },
  {
    titleText: "NORMAL BODY TEXT",
    children: <>Typography Inputs come here...</>,
    isOpenByDefault: true,
  },

  {
    titleText: "SMALL BODY TEXT",
    children: <>Typography Inputs come here...</>,
    isOpenByDefault: true,
  },

  { titleText: "TEXT LINK", children: <>Typography Inputs come here...</>, isOpenByDefault: true },

  {
    titleText: "BUTTON LABEL",
    children: <>Typography Inputs come here...</>,
    isOpenByDefault: true,
  },
];
