import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Flex, Stack, useDisclosure } from "@chakra-ui/react";

import { onSubmitFormProps } from "components/modals/version-edit-modal/version-edit-modal";
import toast from "components/partials/toast/toast";
import EmailDesignPreview from "containers/admin/clients/touchpoint/components/email-design-preview/email-design-preview";
import LPDesignPreview from "containers/admin/clients/touchpoint/components/lp-design-preview/lp-design-preview";
import EmptyDesignPreview from "containers/admin/clients/touchpoint/components/empty-design-preview/empty-design-preview";

import {
  builderPreviewMenuOptions,
  BuilderPreviewPaneHeader,
  BuilderPreviewViews,
} from "containers/admin/clients/touchpoint/components/builder/builder-preview-pane-header/builder-preview-pane-header";
import {
  EmailHeaders,
  initialEmailHeaders,
} from "containers/admin/clients/touchpoint/components/email-builder/email-header-preview/email-header-preview";
import { LoadingSpinner } from "components/partials/loading-screen/loading-screen";
import { BuilderHtmlPreview } from "containers/admin/clients/touchpoint/components/builder/builder-html-preview/builder-html-preview";
import { NoVersionFound } from "containers/admin/clients/touchpoint/components/no-version-found/no-version-found";

import { useCurrentClient, useCurrentCampaign } from "state/ducks";
import { setTouchpointVersions, useCurrentTouchpoint } from "state/ducks/touchpoint";
import TouchpointVersion, {
  TouchpointResponse,
  TouchpointVersionAttributes,
} from "models/touchpoint-version";
import { Email } from "models";

import { getErrorMessage } from "utilities";

import { TouchpointType } from "types/touchpoint";
import { removeUserIdFromString } from "utilities/touchpoint";
import { isLandingPage } from "types/typeguards";
interface TouchpointPreviewTabContentProps {
  selectedVersion?: TouchpointVersionAttributes;
}
export const TouchpointPreviewTabContent = ({
  selectedVersion,
}: TouchpointPreviewTabContentProps) => {
  const location = useLocation();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const dispatch = useDispatch();
  const { desktopView, mobileView } = builderPreviewMenuOptions;
  const createFirstDraftModal = useDisclosure();
  const [currentView, setCurrentView] = useState<BuilderPreviewViews>(mobileView);
  const [touchpointPreviewHTML, setTouchpointPreviewHTML] = useState<string>();
  const [touchpointFinalHTML, setTouchpointFinalHTML] = useState<string>();
  const [previewError, setPreviewError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCommentMode, setIsCommentMode] = useState<boolean>(false);
  const [touchpointJSON, setTouchpointJson] = useState<string>("");
  const [emailHeaders, setEmailHeaders] = useState<EmailHeaders>(initialEmailHeaders);
  const isDesktopPreview = currentView === desktopView;
  const isMobilePreview = currentView === mobileView;
  const isDesignPreview = isDesktopPreview || isMobilePreview;

  function setTouchpointValues(touchpointData: TouchpointResponse) {
    if (touchpointData.finalHtml) {
      const touchpointDisplayHTML = removeUserIdFromString(touchpointData.finalHtml);
      setTouchpointPreviewHTML(touchpointDisplayHTML);
      setTouchpointFinalHTML(touchpointData.finalHtml);
    }

    setTouchpointFinalHTML(touchpointData.finalHtml);

    if (touchpointData.data?.jsonOutput) {
      setTouchpointJson(touchpointData.data.jsonOutput);
    }

    if (touchpointData.type === TouchpointType.EMAIL) {
      const email = touchpointData as Email;
      const emailHeaders: EmailHeaders = {
        fromName: email.fromName || initialEmailHeaders.fromName,
        replyToEmail: email.replyToEmail || initialEmailHeaders.replyToEmail,
        subjectLine: email.subjectLine || initialEmailHeaders.subjectLine,
        preheader: email.preheader || initialEmailHeaders.preheader,
        senderEmail: email.senderEmail || initialEmailHeaders.senderEmail,
      };
      setEmailHeaders(emailHeaders);
    }
  }

  const loadTouchpointVersions = () => {
    TouchpointVersion.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
      options: { page: 0 },
    }).then((response) => {
      const resultsAttributes = response.items.map((v) => v.attributes);
      dispatch(setTouchpointVersions({ ...response, items: resultsAttributes }));
    });
  };

  const createDraftSubmit = ({ version, versionNotes, visibleToClient }: onSubmitFormProps) => {
    TouchpointVersion.create({
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
      version: version,
      versionNotes: versionNotes,
      visibleToClient: visibleToClient,
    })
      .then(() => {
        toast.success({
          data: {
            title: "Draft Created",
          },
        });
        loadTouchpointVersions();
      })
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create draft",
            message: getErrorMessage(err?.response?.data),
          },
        });
        loadTouchpointVersions();
      })
      .finally(() => {
        createFirstDraftModal.onClose();
      });
  };

  useEffect(() => {
    if (selectedVersion?.id) {
      setIsLoading(true);
      TouchpointVersion.find({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        id: selectedVersion?.id,
      })
        .then((touchpointData) => {
          setPreviewError(false);
          setTouchpointValues(touchpointData);
        })
        .catch((err) => {
          setPreviewError(true);
          toast.error({
            data: {
              title: "Failed to save theme",
              message: getErrorMessage(err?.response?.data),
            },
          });
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [selectedVersion?.id]);

  if (isLoading) {
    return (
      <Flex mt="10" justifyContent="center">
        <LoadingSpinner />
      </Flex>
    );
  }

  if (!selectedVersion?.id || previewError) {
    return (
      <NoVersionFound touchpointType={currentTouchpoint.type} onCreateDraft={createDraftSubmit} />
    );
  }

  const dataTestId = isDesktopPreview
    ? "touchpoint-desktop-previewType"
    : "touchpoint-mobile-previewType";

  const builderRoute = isLandingPage(currentTouchpoint) ? "landing-page-builder" : "email-builder";
  const designTabRedirect = location.pathname.replace(
    "preview",
    `versions/${selectedVersion?.id}/${builderRoute}/design`,
  );
  const customClass = isMobilePreview ? "max-w-[25rem]" : "max-w-full";

  if (touchpointPreviewHTML && touchpointFinalHTML) {
    return (
      <Stack
        data-testid="preview-tab-content"
        alignItems="center"
        flex="1"
        marginX="auto"
        spacing="0"
        width="full">
        <BuilderPreviewPaneHeader
          currentView={currentView}
          selectedVersion={selectedVersion}
          setCurrentView={setCurrentView}
          isCommentMode={isCommentMode}
          setIsCommentMode={setIsCommentMode}
          isTouchpointPreview={true}
        />
        <div className={`w-full pt-2 ${customClass}`} data-testid={dataTestId}>
          {isDesignPreview ? (
            currentTouchpoint.type === TouchpointType.LANDING_PAGE ? (
              <LPDesignPreview
                htmlContent={touchpointPreviewHTML}
                htmlJSON={touchpointJSON}
                isCommentMode={isCommentMode}
                mobileView={isMobilePreview}
              />
            ) : (
              <EmailDesignPreview
                emailHeaders={emailHeaders}
                htmlContent={touchpointPreviewHTML}
                htmlJSON={touchpointJSON}
                isCommentMode={isCommentMode}
                mobileView={isMobilePreview}
              />
            )
          ) : (
            <BuilderHtmlPreview
              data-testid="preview-html"
              textareaTestId="preview-textarea"
              value={touchpointFinalHTML}
            />
          )}
        </div>
      </Stack>
    );
  }
  return <EmptyDesignPreview redirectTo={designTabRedirect} />;
};
