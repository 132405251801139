import React from "react";
import { Link } from "react-router-dom";
import { Box, BoxProps, Grid, HStack, List, ListItem, Text } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import { H2 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import Card, { CardBody, CardHeader, CardRow } from "components/partials/card/card";
import PageContentHeader from "components/partials/page-content-header/page-content-header";

import { useGetProgramTypesQuery, getDictionaryItemDescription } from "state/api/dictionary";
import { useCurrentCampaign, useCurrentUser } from "state/ducks";
import Campaign from "models/campaign";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

import { addHyphenToAudienceSelection, toSpacedTitleCase, toTitleCase } from "utilities";

export interface ParamsType {
  campaignId?: string;
  clientId?: string;
  touchpointId?: string;
}

const CampaignSettingsPage = () => {
  const campaign = useCurrentCampaign();
  const currentUser = useCurrentUser();

  return (
    <Box date-testid="client-campaign-settings-page">
      <PageContentHeader className="pt-5">
        <H2>Campaign Settings</H2>
        <Link to="edit">
          {hasPermission(currentUser, Permission.PERM_CAMPAIGN_WRITE) && (
            <Button variant="secondary" leftIcon={<EditIcon />} data-testid="edit-campaign-button">
              Edit
            </Button>
          )}
        </Link>
      </PageContentHeader>

      <Grid>
        <HStack align="start" spacing={6}>
          <ClientCampaignBasicInfoCard campaign={campaign} />
          <ClientCampaignAudienceAndStrategyCard campaign={campaign} mb={6} />
        </HStack>
      </Grid>
    </Box>
  );
};

interface ClientCampaignCardProps extends BoxProps {
  campaign: Campaign;
}

const ClientCampaignBasicInfoCard: React.FC<ClientCampaignCardProps> = ({ campaign, ...rest }) => {
  const { data: programTypes = [] } = useGetProgramTypesQuery();
  return (
    <Card {...rest}>
      <CardHeader heading="Basic details" />
      <CardBody>
        <CardRow title="Campaign Name" value={campaign.name} />
        <CardRow
          title="Program Type"
          value={getDictionaryItemDescription(programTypes, campaign.programType)}
        />
        <CardRow title="Campaign CDN Profile" value={campaign.cdnSubdirectoryName} />
      </CardBody>
    </Card>
  );
};

const ClientCampaignAudienceAndStrategyCard: React.FC<ClientCampaignCardProps> = ({
  campaign,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <CardHeader heading={"Audience & Strategy"} />
      <CardBody>
        <CardRow
          title="Campaign Objective"
          value={
            campaign.campaignObjective && (
              <Text textAlign="right">
                {campaign.campaignObjective && toSpacedTitleCase(campaign.campaignObjective)}
              </Text>
            )
          }
        />
        <CardRow
          title="Audience"
          value={
            campaign.audiences &&
            campaign.audiences.length > 0 && (
              <List ml={6}>
                {campaign.audiences.map((audience) => {
                  return (
                    <ListItem key={audience} whiteSpace="nowrap" textAlign="right">
                      {addHyphenToAudienceSelection(toSpacedTitleCase(audience))}
                    </ListItem>
                  );
                })}
              </List>
            )
          }
        />
        <CardRow
          title="Class"
          value={
            campaign.classes &&
            campaign.classes.length > 0 && (
              <List ml={6}>
                {campaign.classes &&
                  campaign.classes.map((studentClass) => {
                    return (
                      <ListItem key={studentClass} textAlign="right">
                        {toSpacedTitleCase(studentClass)}
                      </ListItem>
                    );
                  })}
              </List>
            )
          }
        />
        <CardRow title="Population Type" value={toTitleCase(campaign.populationType)} />
      </CardBody>
    </Card>
  );
};

export default CampaignSettingsPage;
