import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BoxProps, Flex, useDisclosure } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { RiDeleteBinFill } from "react-icons/ri";

import { H2 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import Input from "components/forms/input/input";

import Card, { CardBody, CardHeader, CardRow } from "components/partials/card/card";
import ExternalLink from "components/partials/link/link";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import toast from "components/partials/toast/toast";

import { useCurrentUser, useCurrentClient } from "state/ducks";
import Client from "models/client";

import { hasPermission } from "utilities/user";
import { getErrorMessage } from "utilities/index";

import { Permission } from "types/auth";
import PageContent from "components/partials/page-content/page-content";

export const ProfilePage = () => {
  const client = useCurrentClient();
  const currentUser = useCurrentUser();
  const confirmCancelModal = useDisclosure();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  }, []);

  const handleClientDelete = () => {
    Client.delete({
      clientId: client.id,
    })
      .then(() => {
        toast.success({
          data: {
            title: "Client account has been deleted",
            message: `You've successfully deleted ${client.name} client`,
          },
        });
        navigate("/clients");
      })
      .catch((err) => {
        toast.error({
          data: {
            title: `Failed to delete ${client.name} client`,
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
    confirmCancelModal.onClose();
  };

  return (
    <>
      <PageContent className="py-5">
        <Flex direction="column" alignItems="left" w={{ md: "50%" }}>
          <PageContentHeader ml={0} gap={1}>
            <H2>Profile</H2>
            {hasPermission(currentUser, Permission.PERM_CLIENT_WRITE) && (
              <Flex gap="3">
                <Link to="edit">
                  <Button variant="secondary" leftIcon={<EditIcon />}>
                    Edit
                  </Button>
                </Link>
                <Button
                  variant="secondary"
                  onClick={confirmCancelModal.onOpen}
                  leftIcon={<RiDeleteBinFill />}>
                  Delete
                </Button>
              </Flex>
            )}
          </PageContentHeader>
          <ClientBasicInfoCard client={client} />
        </Flex>
      </PageContent>
      <ConfirmationModal
        {...confirmCancelModal}
        onClose={() => {
          setInputValue("");
          confirmCancelModal.onClose();
        }}
        headline="Delete client"
        message={
          <>
            This will remove the client from Apollo permanently. This action cannot be undone. This
            will not automatically remove the client's data from related systems, and a request
            should be made to{" "}
            <Link
              className="text-navy underline"
              to="#"
              onClick={(e) => {
                window.location.href =
                  "mailto:support@marketviewedu.com?subject=Remove all client's data";
                e.preventDefault();
              }}>
              support@marketviewedu.com
            </Link>{" "}
            to finish off-boarding the client.
          </>
        }
        cancelButtonText="Cancel"
        confirmButtonText="Submit"
        onConfirm={handleClientDelete}
        modalType="warning"
        disabled={inputValue !== client?.name}>
        <Input
          placeholder="Client Name..."
          value={inputValue}
          id="client-name"
          data-testid="client-name-input"
          label="Please confirm this action by typing the client name"
          onChange={handleChange}
        />
      </ConfirmationModal>
    </>
  );
};

interface ClientCardProps extends BoxProps {
  client: Client;
}

const ClientBasicInfoCard: React.FC<ClientCardProps> = ({ client, ...rest }) => {
  return (
    <Card {...rest}>
      <CardHeader heading="Basic Information" />
      <CardBody>
        <CardRow title="Client name" value={client.name} />
        <CardRow title="Client prefix" value={client.clientCode} />
        <CardRow title="Apollo profile code" value={client.tealiumProfileCode} />
        <CardRow title="Client Apollo URL" value={client.apolloDomainUrl} />
        <CardRow
          title="Client .edu URL"
          value={
            client.collegeUrl && (
              <ExternalLink href={client.collegeUrl}>{client.collegeUrl}</ExternalLink>
            )
          }
        />
        <CardRow
          title="Client privacy policy URL"
          value={
            client.privacyPolicyUrl && (
              <ExternalLink href={client.privacyPolicyUrl}>{client.privacyPolicyUrl}</ExternalLink>
            )
          }
        />
      </CardBody>
    </Card>
  );
};
