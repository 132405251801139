import { useEffect } from "react";

import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApolloSelect } from "@marketview/lunar-components";

import {
  THEME_MOCK_DATA,
  MOCKED_VERSIONS,
} from "containers/admin/clients/client/theme-page/theme-page-mock-data";
import ThemePreview from "containers/admin/clients/client/theme-page/preview/preview";
import ThemeVersionHistory from "containers/admin/clients/client/theme-page/version-history/version-history";

import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";

import { setCurrentTheme } from "state/ducks/themes";

import { getClientPaths } from "utilities/app-routes";

const useThemeNavigation = () => {
  const { clientId, "*": currentPath } = useParams();
  const navigate = useNavigate();
  const { clientThemePath } = getClientPaths({ clientId });

  const navigateToPath = (path: string) => {
    navigate(`${clientThemePath}/${path}`);
  };

  return { currentPath, clientThemePath, navigateToPath };
};

const PREVIEW_PATH = "preview";

const THEME_TABS = [
  { name: "Preview", path: PREVIEW_PATH },
  { name: "Version History", path: "versions" },
] as const;

const ThemePage = () => {
  const dispatch = useDispatch();
  const { currentPath, navigateToPath } = useThemeNavigation();

  useEffect(() => {
    return () => {
      dispatch(setCurrentTheme(THEME_MOCK_DATA));
    };
  }, [dispatch]);

  const handleVersionChange = () => {
    // TODO: Implement real version change logic
    if (currentPath === PREVIEW_PATH) return;
    navigateToPath(PREVIEW_PATH);
  };

  const handleTabChange = (tab: string) => {
    const selectedTab = THEME_TABS.find((t) => t.name === tab);
    if (selectedTab) navigateToPath(selectedTab.path);
  };

  return (
    <>
      <div className="flex flex-col bg-neutral-4 px-8 pt-4">
        <div className="flex justify-between items-center mb-2">
          <H2 className="!text-2xl !leading-[30px]">Theme Configuration</H2>
          <div className="w-56">
            <ApolloSelect
              ariaLabel="Select a theme version"
              name="theme-versions-select"
              size="md"
              defaultValue={MOCKED_VERSIONS[0]}
              options={MOCKED_VERSIONS}
              onChange={handleVersionChange}
            />
          </div>
        </div>
        <ToggleButtonGroup
          variant="primary"
          groupName="theme-tabs-toggle"
          options={THEME_TABS.map((t) => t.name)}
          value={THEME_TABS.find((t) => t.path === currentPath)?.name}
          onChange={handleTabChange}
        />
      </div>
      <Routes>
        <Route index element={<Navigate to={PREVIEW_PATH} replace />} />
        <Route path={PREVIEW_PATH} element={<ThemePreview />} />
        <Route path="versions" element={<ThemeVersionHistory />} />
      </Routes>
    </>
  );
};

export default ThemePage;
